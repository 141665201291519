#goalTable .ant-table-thead > tr > th {
  background-color: rgba(186, 186, 186, 0.38) !important;
  /* @apply !bg-[#BABABAB] */
}

#goalTable
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  background-color: transparent !important;
}

#goalTable .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  background-color: rgba(186, 186, 186, 0.38) !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  /* border: none !important; */
  /* margin-left: px !important; */
}

#goalTable .ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: transparent !important;
  border: 0px !important;
}
