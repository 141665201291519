#policy {
  /* font-size: 30px; */
}
#customer {
  /* font-size: 30px; */
}
#customerinfo {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
}
