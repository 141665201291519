.accordion_icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.accordion_content {
  transition: max-height 0.6s ease;
  overflow: auto;
}

.accordion_content2 {
  transition: max-height 0.6s ease;
  /* overflow: auto; */
}

.accordion_content::-webkit-scrollbar {
  display: none;
}
