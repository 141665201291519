#employeeFilter.ant-select-selector {
  width: 50px;
}

/* #employeePage .ant-table-container {
  border-block: 14px solid white;
  border-inline: 16px solid white;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
} */
/* #employeePage .ant-table-wrapper .ant-table-thead {
  border-radius : 0px !important;
  background-color: red !important;
} */
/* #employeePage .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #eef2ff !important;
  border-radius: 0px !important;
  padding-block: 8px !important;
} */
