#compare .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
#compare .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  @apply text-x 2xl:text-xxs !h-auto;
}

.product_benefits ul li {
  @apply flex gap-2 before:rounded-full before:w-3 before:h-1.5 text-grey text-x;
}

.product_benefits {
  @apply px-2 2xl:px-4;
}

.product_benefits ul li::before {
  font-size: 86px;
  line-height: 0px;
  content: '·';
  color: #8d8787;
  padding-top: 2px;
}

.max-lines {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.details_icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}
