/* .ant-tabs
  :is(
    .ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab:hover,
    .ant-tabs-tab-btn:focus:not(:focus-visible)
  ) {
  @apply !text-primary-90 !font-normal;
} */

:is(.ant-tabs) {
  font-family: var(--mainFont);
}
.ant-tabs-nav {
  /* @apply bg-white p-2 pt-0 pb-0 rounded-lg; */
}
:is(.ant-tabs) .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px;
}
:is(.ant-tabs-top) > .ant-tabs-nav::before {
  border-bottom: 0;
}

/* .ant-tabs-content-holder {
  @apply bg-white rounded-lg p-4;
} */

.ant-tabs-tab {
  @apply text-grey-25 after:content-[''] after:inline-block after:bg-grey-light after:ml-3.5 after:my-0 after:h-3.5 after:w-0.5 after:align-middle last:after:bg-white;
}

.ant-tabs :is(.ant-tabs-ink-bar) {
  @apply bg-transparent;
}

.ant-tabs
  :is(
    .ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab:hover,
    .ant-tabs-tab-btn:focus:not(:focus-visible)
  ) {
  text-shadow: none;
  @apply font-medium;
}

/* .ant-tabs-ink-bar::after {
  content: ' ';
  position: absolute;
  left: 45%;
  right: 0;
  bottom: 0;
  height: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--primary-90);
  width: 60%;
  transform: translateX(-50%);
} */

.ant-card-body {
  @apply !p-0;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #c1c1c1 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar {
  @apply h-2.5;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-card {
  @apply bg-transparent overflow-y-scroll  scrollbar-hide;
}

.ant-tabs-nav {
  background: transparent !important;
  border: none;
  font-weight: 500;
}
.ant-select-selection-placeholder,
.ant-select-selection-item {
  /* @apply !text-xxs font-medium !text-primary-90; */
}

.ant-card-body {
  @apply bg-transparent scrollbar-hide overflow-y-scroll;
  font-family: var(--mainFont);
}

.ant-card-body::-webkit-scrollbar {
  background-color: #00000000;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.customLeadsTab .ant-tabs-tab::after {
  @apply !h-0 !ml-0;
}
.ant-picker-range .ant-picker-active-bar {
  @apply bg-primary-90;
}
