.ant-notification {
  font-family: var(--mainFont);
}
.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-description,
.ant-notification-notice-message {
  font-family: var(--mainFont);
}

:where(.css-dev-only-do-not-override-j0nf2s)[class^='ant-notification'],
:where(.css-dev-only-do-not-override-j0nf2s)[class*=' ant-notification'] {
  font-family: var(--mainFont);
}

:is(.ant-notification-notice) {
  padding: 1.125rem;
}

.ant-notification-notice .ant-notification-notice-with-icon :is(.ant-notification-notice-message) {
  @apply text-sm mb-0 font-medium;
}
.ant-notification-notice :is(.ant-notification-notice-icon) {
  @apply top-7 left-2.5;
}

.ant-notification-notice :is(.ant-notification-notice-close) {
  @apply h-7 w-7 top-7;
}

.ant-notification-notice .ant-notification-notice-close:hover {
  background-color: inherit;
}
