/* Text input  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.required:after {
  content: ' *';
  color: red;
}

/* select */
.select__input-container {
  @apply !p-0 !m-0;
}

.css-olqui2-singleValue {
  @apply !text-black !text-xs;
}

/* Text input Ends */

/* Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 14px;
  color: #000b10;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check-box {
  position: absolute;
  inset: 0;
  border-radius: 4px;
  border: 1px solid #5d5d5d;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .check-box {
  background-color: var(--primary-90);
  border-color: var(--primary-90);
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-box:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .check-box:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .check-box:after {
  left: 7px;
  top: 3px;
  bottom: 6px;
  right: 6px;
  width: 33%;
  height: 60%;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.container {
  display: flex;
  /* align-items: center; */
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  color: #5d5d5d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px !important;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid #49de0b;
  /* background-color: #49de0b; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #49de0b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* checkbox tertiary variant */

.container .checkmark.round-checkmark::after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 13px;
  border-width: 0 2px 2px 0;
}

.round-checkmark {
  border: 1px solid #5d5d5d;
}

.container input:checked ~ .round-checkmark {
  background-color: #269f32;
  border-color: #269f32;
}

/* Checkbox Ends */

/* DATE TIME PICKER */
.ant-picker,
.ant-picker-dropdown {
  font-family: var(--mainFont);
}
.ant-picker .ant-picker-input > input {
  @apply text-xl md:text-xxs xl:text-xs;
}

.ant-picker-dropdown .ant-picker-header > button:hover,
.ant-picker-dropdown .ant-picker-today-btn,
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th:hover,
.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: var(--primary-90);
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  color: #5d5d5d;
  font-weight: bolder;
}

.ant-picker:hover {
  @apply border-grey-90;
}
.ant-picker-focused {
  border: 1px solid var(--primary-90);
  box-shadow: none;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-90);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--primary-90);
}

.ant-btn-primary {
  @apply bg-primary-90;
}

.ant-btn-primary:not(:disabled):hover {
  @apply bg-primary-80;
}

.ant-picker-now-btn:hover {
  @apply text-primary-90;
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  @apply bg-primary-80;
}

.ant-picker-input > input {
  @apply placeholder:!text-grey-90;
}

/* Search API */
.ant-select-dropdown,
.ant-select-selection-item,
.ant-select-selection-search-input {
  font-family: var(--mainFont);
}
/* .ant-select:hover .ant-select-clear {
  opacity: 0;
} */

.ant-select-selector {
  background-color: var(--grey-light) !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  @apply border-0;
}

.ant-select-selector {
  /* @apply !p-0; */
}
.ant-select-show-search .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  margin: 0px -10px;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector,
.ant-select-focused
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  box-shadow: none;
}

.ant-select-show-search .ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  font-family: var(--mainFont);
}

.ant-select-disabled .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply bg-transparent;
}

.ant-select-selection-placeholder {
  font-family: var(--mainFont);
  @apply text-xs !text-grey-90;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  @apply absolute right-2.5;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  @apply text-xxs xl:text-xs !h-auto;
}

/* Currency Input */
.ant-input-number {
  box-shadow: none;
  @apply bg-[#F5FAFD] border-grey-90 transition-none hover:border-grey-90 hover:outline-none
   group-focus-within:!border-primary-90  py-1.5;
  font-family: var(--mainFont);
}
.ant-input-number-group .ant-input-number-group-addon,
.ant-select-selector {
  @apply border-grey-90 group-focus-within:border-primary-90;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-grey-70;
}

.ant-input-number-group .ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group .ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  @apply text-primary-90;
}

/* Upload File */
.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 4px 0;
}
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--primary-90);
}

/* switch  */
.ant-switch {
  @apply !bg-grey-90;
}

.ant-switch-checked {
  @apply !bg-primary-60;
}

/* radio */
.ant-radio-wrapper span.ant-radio + * {
  font-family: var(--mainFont);
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--primary-90);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: white;
  border-color: var(--primary-90);
}

.ant-radio-wrapper .ant-radio-inner {
  border-color: var(--grey-90);
}

.ant-radio-wrapper .ant-radio-inner::after {
  background: var(--primary-90);
}

#select-list-item .ant-checkbox-inner {
  width: 18px;
  height: 18px;
}
#select-list-item .select__control {
  @apply !min-h-[34px]
  /* width: 18px; */
  /* height: 34px !important; */
  /* padding-top: 0px !important; */
  /* background-color: red; */;
}
#select-list-item .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-90);
  border-color: var(--primary-90);
}
#select-list-item .ant-checkbox-checked .ant-checkbox-inner:after {
  height: 10px;
  top: 45%;
}

#searchApi .ant-select-selection-placeholder {
  @apply text-xxs !-ml-2;
}

#searchApi .ant-select-selection-placeholder,
.ant-select-selection-item {
  font-size: 14px;
}

/* #select-list-item .hide-cross-button,
[aria-label^="Remove"] {
  display: none !important;
} */

#searchApi .ant-select-selection-placeholder {
  color: rgb(179, 176, 176) !important;
}

.ant-upload-wrapper .ant-upload-select {
  width: 100%;
}

#common-date-picker .ant-picker .ant-picker-input > input {
  font-size: 14px !important;
  /* height: 20px; */
}

#currency-input .ant-input-number {
  @apply text-xxs placeholder:text-xxs border-[#D1EAF9] py-[1px];
}
#currency-input .ant-input-number-group-addon {
  @apply text-xxs placeholder:text-xxs border-[#D1EAF9] bg-[#F5FAFD];
}

#TimePicker .ant-picker .ant-picker-input > input {
  height: 22px;
  @apply placeholder:text-xxs text-xxs;
}

#exp-input .ant-input-number-group > .ant-input-number:last-child {
  border-color: rgba(209, 234, 249, 1);
  @apply py-[1px]
  /* height: 20px !important; */;
}
#exp-input .ant-input-number-group .ant-input-number-group-addon:first-child {
  border-color: rgba(209, 234, 249, 1);
}
