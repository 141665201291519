/* Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 14px;
  color: #000b10;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checked-box {
  position: absolute;
  /* inset: 0; */
  border-radius: 4px;
  border: 1px solid #5d5d5d;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checked-box {
  background-color: var(--primary-90);
  border-color: var(--primary-90);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checked-box:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checked-box:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checked-box:after {
  left: 7px;
  top: 3px;
  bottom: 6px;
  right: 6px;
  width: 33%;
  height: 60%;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
