.ant-input {
  height: 43px;
  /* border-color: #8b8b8b !important; */
}
.ant-input-group-addon {
  border-color: #8b8b8b !important;
}
.ant-collapse {
  font-family: var(--mainFont);
  @apply border border-grey-90;
}
/* .ant-collapse > .ant-collapse-item, */
.ant-collapse .ant-collapse-content {
  @apply border-b border-b-grey-90;
}

.ant-collapse .ant-collapse-content {
  @apply border-t border-t-grey-90;
}
/* .ant-row {
  @apply grid;
} */

.ant-table-wrapper .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin-block: 0px;
  /* margin-inline: 0px; */
}
