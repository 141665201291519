.ant-modal {
  font-family: var(--mainFont);
}

.ant-modal .ant-modal-close:hover {
  background: none;
}
.ant-modal .ant-modal-content {
  padding: 0;
}
