.dashboardSelectItems .ant-tag {
  @apply !bg-[#CCF0F8] !font-medium px-2 py-0.5 text-xs flex;
}
.dashboardSelectItems .ant-tag svg {
  @apply fill-[#41CAC8] !h-3.5 !w-3.5;
}

.dashboardSelectItems .ant-select-selection-item {
  @apply !bg-[#CCF0F8] border-none;
}
