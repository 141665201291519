#fb-render .rendered-form {
  display: grid;
  gap: 1rem;
}

@media (min-width: 600px) {
  #fb-render .rendered-form {
    @apply grid grid-cols-3 gap-x-6;
  }
}

#fb-render .rendered-form input {
  padding: 7px;
  @apply border border-grey-90 rounded-md focus:outline-none focus:border-primary-90;
}
#fb-render .rendered-form textarea {
  padding: 7px;
  @apply border border-grey-90 rounded-md focus:outline-none focus:border-primary-90;
}
#fb-render .rendered-form select {
  padding: 7px;
  @apply border border-grey-90 rounded-md bg-transparent focus:outline-none focus:border-primary-90;
}
.form-group {
  display: grid !important;
}

.form-control {
  margin-top: 10px;
}
.formbuilder-radio {
  margin-top: 10px;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  @apply bg-primary-90 border-primary-90;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  @apply border-primary-90;
}

/* #leads . */
#insuer .select__control {
  @apply !h-fit;
}

#insurer .customCssForLead .customCss {
  @apply !h-9 -mt-1;
}
