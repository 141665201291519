:is(.ant-tabs) {
  font-family: var(--mainFont);
}
.ant-tabs-nav {
  /* @apply bg-white p-2 pt-0 pl-4 pb-0 rounded-lg; */
}
#customer :is(.ant-tabs) .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px;
}

:is(.ant-tabs) .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 12px;
}

:is(.ant-tabs-top) > .ant-tabs-nav::before {
  border-bottom: 0;
}

/* .ant-tabs-content-holder {
  @apply bg-white rounded-lg p-4;
} */

.ant-tabs-tab {
  @apply text-grey-15 after:content-[''] after:inline-block after:bg-grey-light after:ml-3.5 after:my-0 after:h-3.5 after:w-0.5 after:align-middle last:after:bg-white;
}

.ant-tabs :is(.ant-tabs-ink-bar) {
  @apply bg-transparent;
}

.ant-tabs
  :is(
    .ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab:hover,
    .ant-tabs-tab-btn:focus:not(:focus-visible)
  ) {
  @apply text-primary-90 font-bold;
  text-shadow: none;
}

#customer .ant-tabs .ant-tabs-tab-btn {
  @apply text-xxs  px-3 py-1 bg-[#F0F0F0] rounded-[3px];
  text-shadow: none;
}
#customer .ant-tabs
  :is(
    .ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active,
     /* .ant-tabs-tab:hover, */
    /*.ant-tabs-tab-btn:focus:not(:focus-visible) */
  ) {
  @apply !text-black text-xxs font-semibold px-3 py-1 bg-[#CFEDFF] rounded-[3px];
  text-shadow: none;
}

.ant-tabs-ink-bar::after {
  content: ' ';
  position: absolute;
  left: 45%;
  right: 0;
  bottom: 0;
  height: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--primary-90);
  width: 60%;
  transform: translateX(-50%);
}

#customer .ant-tabs-ink-bar::after {
  content: ' ';
  position: absolute;
  left: 45%;
  right: 0;
  bottom: 0;
  height: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--primary-90);
  width: 0px;
  transform: translateX(-50%);
}

#customer .ant-tabs-top > .ant-tabs-nav {
  @apply m-0;
}

#customer .ant-tabs .ant-tabs-tab {
  padding: 0px;
}
