.rmdp-input {
  width: 100%;
  height: 45px;
  border: 1px solid #8b8b8b;
}
.rmdp-container {
  display: flex !important;
}
.rmdp-day span {
  border-radius: 3px;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background: #26bbed;
  color: white;
}
.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue {
  background: #26bbed;
  color: white;
}
.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background: grey;
}
.rmdp-week-day {
  color: #26bbed;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
}
.rmdp-day,
.rmdp-week-day {
  color: #000;
  cursor: pointer;
  height: 34px;
  position: relative;
  width: 36px;
}
