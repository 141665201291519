.ant-select-tree-treenode {
  @apply border-grey-90 !py-1.5 text-xxs xl:text-xxs text-grey-90 hover:bg-grey-light w-full  overflow-x-hidden;
}

.ant-select-dropdown {
  @apply overflow-x-hidden py-0 rounded-lg -ml-2;
}
/* .ant-select-dropdown {
  box-shadow: 0 0 #0000;
} */

.ant-select-tree-node-content-wrapper {
  @apply bg-transparent	 hover:!bg-grey-light;
}

.ant-select-tree-title {
  font-family: var(--mainFont);
  @apply text-black;
}

.ant-select-tree-node-content-wrapper:hover .ant-select-tree-title {
  font-family: var(--mainFont);
  @apply text-primary-90;
}

#treeSelect .ant-select-selection-placeholder {
  color: rgb(145, 141, 141) !important;
  font-size: 14px !important;
}

/* #treeSelect .ant-select{
  height: 40px;
} */
