.myattendance .ant-picker-input span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.myattendance .ant-picker-input span.ant-picker-suffix svg {
  @apply !fill-primary-90 !h-7 !w-6;
}
.myattendance {
  @apply border-primary-90 border-2;
}
.myattendance {
  @apply !w-40;
}
