#divToPrint .ant-table-thead > tr > th {
  background-color: rgba(186, 186, 186, 0.38) !important;
  /* @apply !bg-[#BABABAB] */
}

#divToPrint
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  background-color: transparent !important;
}

#divToPrint .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  background-color: rgba(186, 186, 186, 0.38) !important;
  /* border: none !important; */
  /* margin-left: px !important; */
}

#divToPrint .ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: transparent !important;
  border: 0px !important;
}
