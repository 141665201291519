.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px;

  /* border: 1px solid #ababab !important; */
  /* padding-left: 10px !important; */
  box-shadow: none;
  background-color: transparent;
  gap: 10px;
}

.ant-select-dropdown {
  min-width: 15ch !important;
}
