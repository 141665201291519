input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50em;
  background: url('../../assets/icons/close.svg') no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  cursor: pointer;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}
