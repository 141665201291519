#leads .ant-select {
  /* background-color: #f5fafd !important; */
  /* border-color: #d1eaf9 !important; */
  /* @apply h-[34px]; */
}
#leads .select__control {
  /* background-color: #f5fafd !important; */
  /* border-color: #d1eaf9 !important; */
  /* @apply h-[34px] -mt-1; */
}
#multiLeads .select__control {
  /* background-color: #f5fafd !important; */
  /* border-color: #d1eaf9 !important; */
  /* @apply !h-9 -mt-1; */
}
#leads .ant-picker {
  /* background-color: #f5fafd !important; */
  /* border-color: #d1eaf9 !important; */
  @apply h-[34px] -mt-1;
}
#leads .ant-picker-suffix {
  /* border-color: #d1eaf9 !important; */
}
#leads .reasonField {
  /* background-color: #f5fafd !important; */
  /* border-color: #d1eaf9 !important; */
}
#leads .multiInsurer .select__control {
  /* @apply !h-fit; */
}

#leads .customCssForLead .leadsForm {
  /* background-color: #f5fafd !important;/ */
  /* border-color: #d1eaf9 !important; */
  /* height: 34px  ; */
  /* @apply -mt-1; */
}
