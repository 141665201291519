.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  @apply rounded-none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  @apply rounded-none border-b border-grey-light;
  font-family: var(--mainFont);
}
.ant-space-item {
  font-family: var(--mainFont);
}
/* .ant-dropdown {
  @apply fixed
} */
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover > span {
  @apply text-primary-90;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover > svg {
  @apply stroke-primary-90;
}
