.ant-drawer .ant-drawer-body {
  padding: 0px !important;
}

.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink {
  @apply !bg-primary-60 w-1 start-full;
}
.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor::before {
  @apply border-s-4 border-s-grey-40 start-full;
}

.ant-anchor-wrapper .ant-anchor .ant-anchor-link-active > .ant-anchor-link-title {
  @apply font-semibold !text-primary-60;
}

.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
  /* @apply text-[#ABABAB]; */
}

.ant-switch-inner-checked {
  @apply text-primary-60 bg-primary-60;
}

.ant-drawer .ant-drawer-header-title {
  @apply flex-row-reverse gap-3;
}

.ant-anchor-link-title-active {
  @apply !text-primary-60;
}
.ant-anchor-link-title {
  @apply !text-[#ABABAB];
}
