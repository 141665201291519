@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primaryColor: #0089cf;
  --primary-90: #26cbed;
  --primary-70: #e1faff;
  --mainFont: 'Poppins', sans-serif;
}

body {
  font-family: var(--mainFont);
  margin: 0;
  /* font-family: 'Segoe UI', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: "Raleway", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  height: 100lvh;
  /* overflow: hidden; */
}

/*added font family for antd drop down*/
/* .ant-space-item {
  font-family: 'Poppins', sans-serif !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #c1c1c1 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar {
  @apply h-2.5;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media print {
  .noPrint {
    display: none;
  }
}

.bordered {
  border: 1px solid #ddd;
}

.ant-tabs-content-holder {
  display: flex;
}

.ant-tabs-tabpane {
  height: 100%;
  overflow-y: auto;
}

#terms-condition {
  height: calc(100vh - 210px);
}

#pos-signup {
  height: calc(100vh - 280px);
}

@media (min-width: 1440px) {
  /* … */
  :root {
    font-size: 18px;
  }
}
#html-viewer {
  ul,
  ol {
    list-style: inside !important;
  }
}
