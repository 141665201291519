.ant-btn-text:not(:disabled):hover {
  @apply bg-white;
}
.ant-btn {
  font-family: var(--mainFont);
}

.custom-tab {
  color: var(--primary-90);
}
.custom-tab::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  height: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--primary-90);
  width: 60%;
  transform: translateX(-50%);
}
