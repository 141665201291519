#add-document .ant-table-wrapper :is(.ant-table-tbody) > tr > td {
  border-bottom: none;
}

#add-document .ant-table-wrapper table {
  padding: 10px 0;
}
#add-document .ant-table-cell {
  padding: 0.375rem 0 !important;
}
