#lead-tab .ant-tabs-nav {
  @apply !mb-0;
}

#lead-tab .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ababab !important;
  padding: 0px 10px !important;
}

/* table */
.customTable .ant-table-header {
  @apply border border-b-0 border-grey-40;
}
.customTable .ant-table-body {
  @apply rounded-b-md border border-grey-40;
}
.customTable .ant-table-thead > tr > th {
  @apply !bg-white  !border-none;
}
.customTable .ant-table-cell:before {
  height: 0px !important;
  width: 0px !important;
}
.customTable .ant-table-cell-scrollbar {
  /* @apply !bg-white; */
}

.lightBtn {
  box-shadow: none !important;
}
.customTable .ant-tabs-ink-bar::after {
  background-color: transparent !important;
}

.customTable .ant-tabs-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff !important;
}
.customTable .ant-tabs-tab {
  @apply !px-2 !py-1 !rounded-full !text-x !min-w-[3.5rem];
}

.customTable .ant-tabs-tab-active {
  @apply !border-grey-70;
}
.customTable .ant-tabs-tab::after {
  @apply !h-0 !ml-0;
}

/* row data */
.customTable .ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}
/* row tags */
.customTable .my-tag {
  font-size: 12px;
}
.customTable .ant-table-thead > tr > th {
  height: 5px;
  padding: 4px;
}
.tabAlignRight .ant-tabs-nav-list {
  @apply justify-end w-full;
}
.customTable .ant-tabs-nav {
  @apply !px-0;
}

.activitySelect .ant-select-selection-placeholder,
.activitySelect .ant-select-selection-item {
  @apply !text-xxs font-medium text-grey-90;
}
.reportIcon svg {
  @apply w-5 h-5;
}

.stats-dash {
  font-family: var(--secFont) !important;
}

#dashboard .ant-select-selection-placeholder,
.ant-select-selection-item {
  @apply !text-xxs;
}

#dashboard .ant-badge.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: text-top;
}

#lead-tab .ant-tabs-tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#lead-tab .ant-tabs-ink-bar {
  display: none !important;
  width: 0px !important;
}

#lead-tab .ant-tabs-tab-btn sup {
  margin-top: 8px;
}

#lead-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply !bg-primary-90 !text-white !px-2 rounded-md py-0.5;
}
#lead-tab .ant-tabs-tab-btn {
  @apply !px-2 rounded-md py-0.5;
}
#lead-tab .ant-tabs-tab {
  @apply !p-0;
}
#lead-tab .ant-tabs-tab::after {
  display: none !important;
}

#lead-tab .ant-tabs-nav-list {
  @apply border border-primary-90 rounded-md lg:ml-[155px];
}

#lead-tab .ant-tabs-tab-active {
  align-items: inherit !important;
}

#dashboard-selector .ant-select-selection-item {
  @apply !text-xxs;
}

#lead-tab .ant-tabs-tab-active .ant-scroll-number {
  @apply !bg-white !text-primary-90;
}
