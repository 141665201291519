#leads .ant-select {
  /* background-color: #f5fafd !important;
  border-color: #d1eaf9 !important;
  @apply !h-9; */
}
#leads .select__control {
  /* background-color: #f5fafd !important;
  border-color: #d1eaf9 !important;
  @apply !h-9 -mt-1; */
}
#leads .ant-picker {
  /* background-color: #f5fafd !important;
  border-color: #d1eaf9 !important;
  @apply !h-9 -mt-1; */
}
#leads .ant-picker-suffix {
  /* border-color: #d1eaf9 !important; */
}
#leads .reasonField {
  background-color: #f5fafd !important;
  border-color: #d1eaf9 !important;
}
#leads .multiInsurer .select__control {
  /* @apply !h-fit; */
}

#leads .customCssForLead .leadsForm {
  /* background-color: #f5fafd !important;
  border-color: #d1eaf9 !important;
  height: 34px;
  @apply -mt-1; */
}

.file-uploader {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-uploader.drag-over {
  background-color: #f0f8ff; /* Light blue background when dragging over */
}

.file-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add more styles as needed */

.selected-files {
  margin-top: 20px;
}

.selected-files ul {
  list-style: none;
  padding: 0;
}

.selected-files li {
  margin-top: 5px;
}
