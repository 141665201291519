.ant-table-wrapper .ant-table {
  font-family: var(--mainFont);
}

.min-h-table .ant-table-wrapper .ant-table {
  min-height: 300px !important;
}

.ant-pagination {
  gap: 8px;
  font-family: var(--mainFont);
  /* background: white; */
  /* padding: 12px; */
  /* border-radius: 8px;  */
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--primaryColor);
  border-radius: 50%;
  border-color: var(--primaryColor);
}
.ant-pagination .ant-pagination-item-active:hover a {
  color: white;
  opacity: 90;
}
:is(.ant-pagination-item:hover) {
  border-radius: 50%;
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  background: var(--primary-70);
}

.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  background: transparent;
  color: var(--primary-90);
}

.ant-pagination .ant-pagination-item-active a {
  color: white;
}

.ant-table-wrapper table {
  /* padding: 10px; */
  /* @apply p-0.5 2xl:p-2.5; */
  font-family: var(--mainFont);
}

.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
}

/* .ant-pagination {
  width: fit-content;
  float: right;
  margin-top: 20px;
  padding: 8px 10px;
  background-color: #fff;
  border-radius: 40px;
} */

:is(li.ant-pagination-prev button span, li.ant-pagination-next button span) {
  display: flex;
  align-items: center;
  gap: 4px;
}

li.ant-pagination-prev button span::after {
  content: 'Prev';
  font-size: 0.875rem;
}

li.ant-pagination-next button span::before {
  content: 'Next';
  display: inline;
  font-size: 0.875rem;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  @apply text-xxs 2xl:text-xs;
}

.ant-pagination .ant-pagination-item-active:hover {
  @apply border-primary-90;
}

/* table font size */
.ant-table-wrapper :is(.ant-table) {
  @apply text-xxs 2xl:text-xs;
}

.ant-table-wrapper .ant-table {
  @apply min-h-[52vh] max-h-full;
}

#dashboard .ant-table-cell {
  @apply !p-2 2xl:!py-3;
  max-width: 100px;
}
.ant-table-cell {
  @apply !p-1 2xl:!py-3;
  max-width: 100px;
}

.ant-space {
  display: flex;
  justify-content: flex-end;
}

:is(ul.ant-pagination li.ant-pagination-total-text) {
  margin-inline-end: auto;
  /* color: var(--grey-90); */
  @apply text-grey-90;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  @apply mb-0;
}

/* .ant-table-body {
  height: 30vh;
} */

.ant-table-content {
  min-height: 40vh;
}

#table .ant-space-align-center {
  align-items: start;
}

#dashboard .ant-table-header {
  @apply border-none;
}
#dashboard .ant-table-body {
  @apply border-none;
}

#dashboard .ant-table-thead > tr > th {
  @apply !border-none;
}
#dashboard .ant-table-cell {
  /* @apply !rounded-none; */
}
#dashboard .ant-table-cell-scrollbar {
}

#table .ant-table-container {
  border-block: 14px solid white;
  border-inline: 16px solid white;
}
#dashboard .ant-table-container {
  border-block: 14px solid white;
  border-inline: 0px solid white;
}
/* #employeePage .ant-table-wrapper .ant-table-thead {
  border-radius : 0px !important;
  background-color: red !important;
} */
#table .ant-table-wrapper .ant-table-thead > tr > th {
  /* background-color: #eef2ff; */
  border-radius: 0px !important;
  padding-block: 8px !important;
}

#table .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  background-color: #bababa61;
  border-radius: 0px !important;
  border: none;
  box-shadow: none;
  /* position: absolute; */
  right: 0px;
}

.ant-table-container {
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11); */
  /* border-bottom-left-radius: 10px !important; */
  /* border-bottom-right-radius: 10px !important; */
}

#table .ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #bababa61 !important;
  padding-block: 8px !important;
}

#table .ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 0px;
}

#table
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  background-color: transparent;
}
